import React from 'react';
import { Grid, Form, Container, Button, Image, Segment, Radio, Header, Divider, TextArea } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import theme from './theme';
import axios from 'axios';

const questions = {
  multichoice: [
    {
      id: 'sex',
      question: { sv: 'Är du kvinna eller man?', us: 'Are you female or male?' },
      choices: {
        sv: ['Kvinna', 'Man', 'Annan / Vill ej svara'],
        us: ['Female', 'Male', 'Other / Do not want to say']
      }
    },
    {
      id: 'age',
      question: { sv: 'Hur gammal är du?', us: 'How old are you?' },
      choices: {
        sv: [
          'Yngre än 18 år',
          '18 - 24 år',
          '25 - 32 år',
          '33 - 40 år',
          '41 - 50 år',
          '51 - 62 år',
          '63 år eller äldre'
        ],
        us: [
          'Younger than 18 years',
          '18 - 24 years',
          '25 - 32 years',
          '33 - 40 years',
          '41 - 50 years',
          '51 - 62 years',
          '63 years or older'
        ]
      }
    },
    {
      id: 'mc',
      question: { sv: 'Vad kör du för typ av motorcykel?', us: 'What kind of motorbike do you ride?' },
      choices: {
        sv: ['Sport', 'Touring', 'Enduro', 'Glidare', 'Naken', 'Trial', 'Offroad', 'Äventyr', 'Annan'],
        us: ['Sport', 'Touring', 'Enduro', 'Custom', 'Naked', 'Trial', 'Offroad', 'Adventure', 'Other']
      },
      other: true
    },
    {
      id: 'time',
      question: { sv: 'Hur länge har du kört motorcykel?', us: 'For how long have you been riding motorbikes?' },
      choices: {
        sv: ['Mindre än 1 år', '1 - 5 år', '6 - 10 år', 'Längre än 10 år'],
        us: ['Less than a year', '1 - 5 years', '6 - 10 years', 'Longer than 10 years']
      }
    },
    {
      id: 'reason',
      question: {
        sv: 'Använder du motorcykeln mest som ett transportmedel eller kör du för nöjes skull?',
        us: 'Do you mostly use your bike as a means of transportation or for fun?'
      },
      choices: {
        sv: ['För transport', 'Nöjeskörning'],
        us: ['For transportation', 'For fun']
      }
    },
    {
      id: 'important',
      question: {
        sv: 'Vad är viktigast för dig när du väljer var du ska köra?',
        us: 'What is most important for you when you decide which route to take?'
      },
      choices: {
        sv: ['Bra vägunderlag', 'Fin utsikt', 'Mycket kurvor', 'Säker väg', 'Kortaste vägen', 'Annat'],
        us: [
          'Good road surface',
          'Nice scenery',
          'A lot of curves',
          'The safety of the road',
          'Shortest way',
          'Something else'
        ]
      },
      other: true
    },
    {
      id: 'accident',
      question: {
        sv: 'Har du någonsin varit med om en mc-olycka?',
        us: 'Have you ever been involved in a motorbike accident?'
      },
      choices: {
        sv: ['Ja, behövde söka vård', 'Ja, sökte ej vård', 'Nej'],
        us: ['Yes, had to go to the hospital', 'Yes, did not go to the hospital', 'No']
      }
    }
  ],
  range: [
    {
      id: 'familiar',
      question: { sv: 'Jag väljer gärna vägar jag kört förut', us: "I prefer to ride routes that I've ridden before" }
    },
    {
      id: 'curves',
      question: {
        sv: 'Jag kör hellre kurviga vägar än raka',
        us: 'I prefer to ride on curvy roads rather than straight ones'
      }
    },
    {
      id: 'friends',
      question: { sv: 'Jag kör helst tillsammans med vänner', us: 'I prefer to ride together with friends' }
    },
    { id: 'gravel', question: { sv: 'Jag älskar att köra grusväg', us: 'I love to ride on gravel roads' } },
    {
      id: 'research',
      question: {
        sv: 'Jag bestämmer var jag ska köra innan jag ger mig ut',
        us: 'I decide where to go before I start riding'
      }
    },
    {
      id: 'discover',
      question: { sv: 'Jag upptäcker gärna nya vägar', us: 'I like to discover new roads' }
    },
    {
      id: 'scenery',
      question: {
        sv: 'Jag tar gärna en omväg för att köra en väg med fin omgivning',
        us: "I don't mind taking a detour to get some nice scenery"
      }
    },
    {
      id: 'highway',
      question: {
        sv: 'Jag kör hellre motorväg än landsväg',
        us: 'I rather drive on the highway than on smaller roads'
      }
    },
    {
      id: 'roadQuality',
      question: {
        sv: 'Vägbanans kvalitet är viktig när jag väljer mina rutter',
        us: 'The quality of the road surface is important when I choose my routes'
      }
    }
  ],
  freeText: [
    {
      id: 'otherFactors',
      question: {
        sv: 'Vilka eventuella övriga faktorer tycker du är viktiga då du väljer rutt att köra?',
        us: 'Are there any other factors you take into consideration when choosing which route to drive?'
      }
    },
    {
      id: 'misc',
      question: {
        sv: 'Har du något övrigt du vill tillägga?',
        us: 'Is there anything else you would like to add?'
      }
    }
  ]
};

const INITIAL_STATE = {
  multichoice: questions.multichoice.reduce((acc, { id }) => ({ ...acc, [id]: -1 }), {}),
  range: questions.range.reduce((acc, { id }) => ({ ...acc, [id]: 0 }), {}),
  freeText: questions.freeText.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}),
  language: window.navigator.languages.includes('sv') ? 'sv' : 'us',
  loading: false,
  sent: false,
  reCaptcha: ''
};

class App extends React.Component {
  state = INITIAL_STATE;

  render() {
    const { sent } = this.state;

    if (sent) {
      return (
        <Container
          style={{
            backgroundColor: theme.systemBackgroundLight,
            boxShadow: '0px 0px 50px 0px rgba(0,0,0,0.75)',
            padding: 10
          }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={12} />
              <Grid.Column width={4}>
                <Image fluid src="./logo.png" style={{ marginTop: 24, marginBottom: 24 }} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment
                  style={{ background: theme.systemBackground, color: theme.white, fontSize: 18, marginBottom: 320 }}
                >
                  <Header style={{ marginBottom: -4, color: theme.white }}>
                    {this.state.language === 'sv' ? 'Tack för din medverkan!' : 'Thanks for your participation!'}
                  </Header>
                  <br />
                  <div>
                    {this.state.language === 'sv' ? `Med vänliga hälsningar, Detecht` : `Best regards, Detecht`}
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }

    const disabled =
      !Object.values(this.state.multichoice).reduce(
        (acc, next) => (acc && next >= 0) || typeof next === 'string',
        true
      ) || !Object.values(this.state.range).reduce((acc, next) => acc && next > 0, true);

    return (
      <Container
        style={{
          backgroundColor: theme.systemBackgroundLight,
          boxShadow: '0px 0px 50px 0px rgba(0,0,0,0.75)',
          padding: 10
        }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              {['us', 'sv'].map(language => (
                <Button
                  key={language}
                  active={this.state.language !== language}
                  inverted
                  secondary
                  onClick={() => this.setState({ language })}
                  style={{ padding: 8 }}
                >
                  <Image src={`https://www.countryflags.io/${language === 'sv' ? 'se' : language}/flat/32.png`} />
                </Button>
              ))}
            </Grid.Column>
            <Grid.Column width={4}>
              <Image fluid src="./logo.png" style={{ marginTop: 24, marginBottom: 24 }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {sent ? (
          <Segment style={{ background: theme.systemBackground, color: theme.white, fontSize: 18 }}>
            <Header style={{ marginBottom: -4, color: theme.white }}>Tack för din medverkan!</Header>
          </Segment>
        ) : (
          <>
            <Segment style={{ background: theme.systemBackground, color: theme.white, fontSize: 18 }}>
              <Header style={{ marginBottom: -4, color: theme.white }}>
                {this.state.language === 'sv' ? 'Hej!' : 'Hi!'}
              </Header>
              <br />
              <div>
                {this.state.language === 'sv'
                  ? `Vi på Detecht undersöker vad som är viktigt för våra användare för att att kunna utveckla ännu bättre funktionalitet. Undersökningen görs som del av en masteruppsats vid Chalmers Tekniska Högskola. Det betyder mycket för oss att ni tar er tid att svarar på följande korta frågor. Alla svar behandlas anonymt.`
                  : `We at Detecht are conducting a survey to gain insight into what is most important for our users. We do this to be able to build even better functionality. The survey is part of a Master Thesis project at Chalmers University of Technology. It means a lot to us that you take your time to do this survey. All collected information is treated anonymously.`}
              </div>
            </Segment>
            <Divider />
            <Form onSubmit={this.submit}>
              <Grid stackable centered>
                {questions.multichoice.map(({ id, question, choices }, index) => (
                  <Grid.Row
                    key={`multichoice-${id}`}
                    style={{
                      backgroundColor: index % 2 === 1 ? theme.stripes : theme.systemBackgroundLight,
                      margin: 4
                    }}
                  >
                    <Grid.Column
                      width={6}
                      style={{ fontSize: 18, color: theme.white }}
                      textAlign="left"
                      verticalAlign="middle"
                    >
                      {question[this.state.language]}
                    </Grid.Column>
                    <Grid.Column width={10}>
                      {choices[this.state.language].length > 3 ? (
                        <>
                          <Form.Dropdown
                            placeholder={this.state.language === 'sv' ? 'Välj...' : 'Choose...'}
                            fluid
                            selection
                            value={
                              isNaN(this.state.multichoice[id])
                                ? questions.multichoice.find(question => question.id === id).choices.us.length - 1
                                : this.state.multichoice[id]
                            }
                            onChange={(_, { value }) => {
                              this.setState({
                                multichoice: { ...this.state.multichoice, [id]: value }
                              });
                            }}
                            options={choices[this.state.language].map((choice, index) => ({
                              key: `multichoice-${id}-${index}`,
                              text: choice,
                              value: index
                            }))}
                          />
                          {questions.multichoice.find(question => question.id === id).other &&
                            (isNaN(this.state.multichoice[id]) ||
                              this.state.multichoice[id] ===
                                questions.multichoice.find(question => question.id === id).choices.us.length - 1) && (
                              <Form.Input
                                placeholder={
                                  this.state.language === 'sv' ? 'Skriv ditt svar här' : 'Fill in your answer here'
                                }
                                fluid
                                onChange={input =>
                                  this.setState({
                                    multichoice: {
                                      ...this.state.multichoice,
                                      [id]: input.target.value.length
                                        ? input.target.value
                                        : choices[this.state.language].length - 1
                                    }
                                  })
                                }
                              />
                            )}
                        </>
                      ) : (
                        <Grid columns={choices[this.state.language].length}>
                          <Grid.Row>
                            {choices[this.state.language].map((choice, index) => (
                              <Grid.Column key={choice}>
                                <div
                                  style={{
                                    width: '100%',
                                    color: theme.white,
                                    textAlign: 'center',
                                    fontSize: 18,
                                    cursor: 'pointer'
                                  }}
                                  onClick={() =>
                                    this.setState({ multichoice: { ...this.state.multichoice, [id]: index } })
                                  }
                                >
                                  <Radio
                                    name={id}
                                    value={index}
                                    checked={index === this.state.multichoice[id]}
                                    style={{ marginBottom: 6 }}
                                    onChange={() =>
                                      this.setState({ multichoice: { ...this.state.multichoice, [id]: choice } })
                                    }
                                  />
                                  <br />
                                  {choice}
                                </div>
                              </Grid.Column>
                            ))}
                          </Grid.Row>
                        </Grid>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
                <Divider />
                <Grid.Row>
                  <Grid.Column width={6} style={{ fontSize: 18, color: theme.white }}>
                    {/* {this.state.language === 'sv'
                      ? 'Dessa påståenden syftar till att ta reda på vad som är viktigt för dig som motorcyklist när du väljer vilken väg du ska köra.'
                      : "These statements serves to gain an understanding into what's important when you choose your routes."} */}
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Grid columns={4}>
                      <Grid.Row>
                        {[1, 2, 3, 4].map(rating => (
                          <Grid.Column key={`rating-header-${rating}`}>
                            <Header style={{ color: theme.white, textAlign: 'center' }}>
                              {rating === 1 && (this.state.language === 'sv' ? 'Håller inte alls med' : 'Disagree')}
                              {rating === 4 && (this.state.language === 'sv' ? 'Håller med helt' : 'Totally agree')}
                            </Header>
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6} />
                  <Grid.Column width={10}>
                    <Grid columns={4}>
                      <Grid.Row>
                        {[1, 2, 3, 4].map(rating => (
                          <Grid.Column key={`rating-header-${rating}`}>
                            <Header style={{ color: theme.white, textAlign: 'center' }} as="h2">
                              {rating}
                            </Header>
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                {questions.range.map(({ id, question }, index) => (
                  <Grid.Row
                    key={`range-${id}`}
                    style={{
                      backgroundColor: index % 2 === 0 ? theme.stripes : theme.systemBackgroundLight,
                      margin: 4
                    }}
                  >
                    <Grid.Column
                      width={6}
                      style={{ fontSize: 18, color: theme.white }}
                      textAlign="left"
                      verticalAlign="middle"
                    >
                      {question[this.state.language]}
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Grid columns={4}>
                        <Grid.Row>
                          {[1, 2, 3, 4].map(rating => (
                            <Grid.Column
                              key={`range-${id}-${rating}`}
                              style={{ textAlign: 'center', cursor: 'pointer', padding: 16 }}
                              onClick={() => this.setState({ range: { ...this.state.range, [id]: rating } })}
                            >
                              <Radio
                                name={id}
                                value={rating}
                                checked={rating === this.state.range[id]}
                                onChange={() => this.setState({ range: { ...this.state.range, [id]: rating } })}
                              />
                            </Grid.Column>
                          ))}
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                ))}
                {questions.freeText.map(({ id, question }, index) => (
                  <Grid.Row
                    key={`freeText-${id}`}
                    style={{
                      backgroundColor: index % 2 === 1 ? theme.stripes : theme.systemBackgroundLight,
                      margin: 4
                    }}
                  >
                    <Grid.Column
                      width={6}
                      style={{ fontSize: 18, color: theme.white }}
                      textAlign="left"
                      verticalAlign="middle"
                    >
                      {question[this.state.language]}
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <TextArea
                        value={this.state.freeText[id]}
                        onChange={input =>
                          this.setState({ freeText: { ...this.state.freeText, [id]: input.target.value } })
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))}
                <Grid.Row>
                  <Grid.Column width={16}>
                    <ReCAPTCHA
                      style={{ margin: '0 auto 0 auto' }}
                      sitekey="6Ldfx9AUAAAAAK84IJKLjARw6p7OaifDAqgfY1nZ"
                      onChange={response => this.setState({ reCaptcha: response })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Button
                  fluid
                  size="big"
                  style={{ background: theme.orange, color: theme.white, margin: 16, marginBottom: 80 }}
                  disabled={disabled || !this.state.reCaptcha || !this.state.reCaptcha.length}
                  loading={this.state.loading}
                >
                  {disabled
                    ? this.state.language === 'sv'
                      ? 'Vänligen svara på alla frågor innan du skickar in'
                      : 'Please respond to all the questions before submitting'
                    : !this.state.reCaptcha || !this.state.reCaptcha.length
                    ? this.state.language === 'sv'
                      ? 'Vänligen klicka i kryssrutan ovan'
                      : 'Please fill in the checkbox above'
                    : this.state.language === 'sv'
                    ? 'Skicka'
                    : 'Send'}
                </Button>
              </Grid>
            </Form>
          </>
        )}
      </Container>
    );
  }

  submit = () => {
    this.setState({ loading: true });

    const { reCaptcha, range, multichoice, freeText } = this.state;

    axios
      .post(`https://us-central1-detecht-development.cloudfunctions.net/submitSurvey`, {
        reCaptcha,
        formData: { range, multichoice, freeText }
      })
      .then(() => {
        console.log('Response recorded');
        this.setState({ ...INITIAL_STATE, sent: true });
      })
      .catch(error => console.log(error));
  };
}

export default App;
